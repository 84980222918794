// Html Body
html, body {
    background: #000;
    color: $pink1;
}

// Body
body {
    font-weight: bold;
}

// Navigation
nav li.nav-item a {
    color: $pink1 !important;
}

//Dropdown Links
.nav-item.dropdown > .dropdown-menu > a.dropdown-item:hover,
.nav-item.dropdown > .dropdown-menu > a.dropdown-item:focus{
    background:none !important;
}
.nav-item.dropdown > .dropdown-menu > a.dropdown-item{
}

//Dropdown Divider
.dropdown-divider{
    border-top:1px solid rgba(235,3,206,0.25) !important;
}

// Colors
.color-pink1 {
    color: $pink1 !important;
}

.color-blue1 {
    color: $blue1 !important;
}

.color-grey1 {
    color: $grey1 !important;
}

.bg-pink1 {
    background-color: $pink1 !important;
}

.bg-blue1 {
    background-color: $blue1 !important;
}

.bg-grey1 {
    background-color: $grey1 !important;
}

// Border Radius
.border-radius-10 {
    border-radius: 10px !important;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px !important;
}

.border-radius-15 {
    border-radius: 15px !important;
    -webkit-border-radius: 15px !important;
    -moz-border-radius: 15px !important;
}

.border-radius-20 {
    border-radius: 20px !important;
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
}

.border-radius-25 {
    border-radius: 25px !important;
    -webkit-border-radius: 25px !important;
    -moz-border-radius: 25px !important;
}

.border-radius-30 {
    border-radius: 30px !important;
    -webkit-border-radius: 30px !important;
    -moz-border-radius: 30px !important;
}

// Font Sizes
/*Font Sizes*/
@for $i from 8 through 50 {
    .font-size-#{$i} {
        font-size: #{$i}px !important;
    }
}

//Font Weight
.font-weight-normal {
    font-weight: 300 !important;
}

.font-style-italic {
    font-weight: 300 !important;
    font-style: italic !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

// Text Gradient
.text-gradient-1 {
    background: -webkit-linear-gradient(left, $pink1, $blue1);
    background: -o-linear-gradient(right, $pink1, $blue1);
    background: -moz-linear-gradient(right, $pink1, $blue1);
    background: linear-gradient(to right, $pink1, $blue1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

// Border Gradient
.border-gradient-1 {
    border: 3px solid;
    border-image-slice: 1;
    border-width: 3px;
    border-image-source: linear-gradient(to left, $pink1, $blue1);
}

// Gradients
.gradient-1 {
    background: #eb03ce !important;
    background: -moz-linear-gradient(left, #eb03ce 0%, #00b4ff 100%) !important;
    background: -webkit-linear-gradient(left, #eb03ce 0%, #00b4ff 100%) !important;
    background: linear-gradient(to right, #eb03ce 0%, #00b4ff 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eb03ce', endColorstr='#00b4ff', GradientType=1) !important;
}

// Card
.card {
    border: none !important;
}

// Btn
.btn {
    font-weight: 700 !important;
}

.btn.btn-pink {
    background-color: $pink1 !important;
}

.btn.btn-blue {
    background-color: $blue1 !important;
}

.btn.btn-outline-pink {
    border-color: $pink1 !important;
}

.btn.btn-outline-blue {
    border-color: $blue1 !important;
}

//Profile Links
.profile-link{
    cursor:pointer;
}

// Custom Grid
.grid-column {

}

.container-grid {
    display: grid !important;
    grid-auto-rows: 1fr !important;
    grid-template-columns: repeat(4, 1fr) !important;
    grid-column-gap: 20px !important;
    grid-row-gap: 20px !important;
    justify-content: center !important;
    align-items: center !important;
    place-self: center;
}

.container-grid .grid-column {
    aspect-ratio: 1 !important;
    width: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    place-self: center;
}

//Pagination
nav ul.pagination{}
nav ul.pagination li.page-item{
}
nav ul.pagination li.page-item .page-link{
    color:$pink1 !important;
    border:none !important;
    outline:none !important;
}
nav ul.pagination li.page-item.active .page-link{
    color:#ffffff !important;
    background:$pink1 !important;
    border:none !important;
    outline:none !important;
}

/* Extra Small devices */
@media (max-width: 576px) {
    .container-grid {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .container-grid {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
    .container-grid {
        grid-template-columns: repeat(4, 1fr) !important;
    }

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .container-grid {
        grid-template-columns: repeat(5, 1fr) !important;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* Extra extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}

